// Color overrides go mostly to config-overrides.js
.ant-layout-content {
  padding: 24px 0;
}

.ant-layout {
  display: flex;
  min-height: 100vh;
}

.ant-notification-notice-description {
  white-space: pre-wrap;
}
