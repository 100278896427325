.wrapper {
  display: flex;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;

  &.transparent {
    background-color: rgba(#FFFFFF, 0.5);
  }
}
