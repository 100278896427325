.space {
  display: flex;

  > * {
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      flex: 1;
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 16px;
    }
  }
}
