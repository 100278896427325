/* body {
}
 */

.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Hide reCAPTCHA badge at the bottom right corner of window
.grecaptcha-badge {
  display: none !important;
}

// Set disabled inputs' color to default
.ant-input-disabled,
.ant-input[disabled],
.ant-picker-input > input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: unset;
}

.info-icon {
  color: #1890FF;
  cursor: help;
}

.avatar-green {
  border-color: #B7EB8F;
  background-color: #F6FFED;
  color: #389E0D;
}

.avatar-gold {
  border-color: #FFE58F;
  background-color: #FFFBE6;
  color: #D48806;
}

.avatar-red {
  border-color: #FFA39E;
  background-color: #FFF1F0;
  color: #CF1322;
}

.modal-info-tag {
  margin: 0;
}
